const csrf_token = $('meta[name="csrf-token"]').attr('content');

$(document).ready(function ()
{

    $(".selectize-select").selectize({
        create: false,
        // sortField: { field: "text", direction: "asc" },
        dropdownParent: "body"
    })

    $("#login_form").submit(function (event)
    {
        // $('#trip_master_form').find('input').attr('required', false);
        if (!$("#login_form")[0].checkValidity())
        {
            $("#login_form")[0].reportValidity()

            return false;
        }

        event.preventDefault();

        var formData = new FormData(this);

        event.preventDefault();

        $('#submitting_btn').removeClass('d-none');
        $('#submit_btn').addClass('d-none');


        formData.append('username', $('#username').val());
        formData.append('password', $('#password').val());
        formData.append('counter', $('#counter').val());
        formData.append('_token', csrf_token);
        $.ajax({
            url: '/counter-do-login',
            type: 'POST',
            data: formData,
            success: function (data)
            {
                console.log(data);

                if (data.status == true)
                {
                    location.replace(data.redirect_location);

                    // swal.fire({ title: "Trip Master Added Successfully" });

                    // // loadTripData();

                    // location.reload();
                }
                else
                {
                    $('#submitting_btn').addClass('d-none');
                    $('#submit_btn').removeClass('d-none');

                    var msg = data.msg;

                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: msg
                    });

                }
            },
            fail:function (response)
            {
                console.log(response);
            },
            cache: false,
            contentType: false,
            processData: false
        });
    });
});